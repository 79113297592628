<template>
  <Dialog :commonDialog="lineItemDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <template v-if="updateData.length > 0">Update Line Item</template>
      <template v-else>Add Line Item</template>
    </template>
    <template v-slot:body>
      <v-form
        ref="lineItemForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <LineItem
          :isQuotation.sync="isQuotation"
          :isContract.sync="isContract"
          :isPurchaseOrder.sync="isPurchaseOrder"
          :isTicket.sync="isTicket"
          v-if="lineItemDialog"
          :taxes.sync="taxes"
          :isNewItem.sync="isNewItem"
          :pageLoading.sync="isPageLoading"
          :updateData.sync="updateData"
          :isSingle.sync="isSingle"
        ></LineItem>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || isPageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="onSubmit"
      >
        Save
      </v-btn>

      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close:line-item', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import LineItem from "@/view/pages/partials/Line-Item.vue";
import { LineItemEventBus } from "@/core/lib/line-item/line.item.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  props: {
    isNewItem: {
      type: Boolean,
      default: false,
    },
    lineItemDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    targetUrl: {
      type: String,
      required: true,
      default: "",
    },
    updateData: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    parent: {
      type: Number,
      required: true,
      default: 0,
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formLoading: false,
      formValid: true,
      isSingle: true,
      isPageLoading: false,
      lineItem: [],
      taxes: [],
    };
  },
  components: {
    LineItem,
    Dialog,
  },
  methods: {
    onSubmit() {
      const _this = this;
      if (!_this.$refs.lineItemForm.validate()) {
        return false;
      }

      let lineItemFormData = new Array();

      for (let i = 0; i < _this.lineItem.length; i++) {
        lineItemFormData.push({
          id: _this.lineItem[i].id,
          product: _this.lineItem[i].product.id,
          group_me: _this.lineItem[i].group_me,
          name: ObjectPath.get(_this.lineItem[i], "name"),
          group_primary: _this.lineItem[i].group_primary,
          tax: _this.lineItem[i].tax,
          product_type: _this.lineItem[i].product.product_type,
          description: _this.lineItem[i].description,
          quantity: _this.lineItem[i].quantity,
          quantity_type: _this.lineItem[i].quantity_type,
          discount: _this.lineItem[i].discount,
          rate: _this.lineItem[i].rate,
          warranty: _this.lineItem[i].warranty,
          warranty_object: _this.lineItem[i].warranty_object,
          warranty_option: _this.lineItem[i].warranty_option,
          minimum_rental: _this.lineItem[i].minimum_rental,
          maximum_rental: _this.lineItem[i].maximum_rental,
          supplier: _this.lineItem[i].supplier,
          category: _this.lineItem[i].category,
        });
      }

      let formData = { line_items: lineItemFormData };
      if (_this.targetUrl == "job") {
        formData["ticket"] = _this.parent;
      } else {
        formData[_this.targetUrl] = _this.parent;
      }

      if (_this.isVisitDetail && _this.lodash.isEmpty(_this.visit) === false) {
        formData["visit"] = _this.lodash.toSafeInteger(_this.visit.id);
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.$store
        .dispatch(PUT, { url: "line-item", data: formData })
        .then(() => {
          _this.$emit("success:line-item", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");*/
  },
  created() {
    const _this = this;
    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 75);
    },
  },
};
</script>
