<template>
  <div class="line-item-detail" v-if="getPermission('line-item:view')">
    <v-container fluid>
      <table class="width-100 custom-border-bottom-5px">
        <thead>
          <tr>
            <th width="700" class="font-size-16 pb-2">
              Product<template v-if="!isPurchaseOrder"
                ><template v-if="!isContract">/Service</template
                >/Equipment</template
              >
            </th>
            <th class="text-center font-size-16 pb-2">Qty.</th>
            <th class="text-center font-size-16 pb-2" v-if="!isTicket">Rate</th>
            <th
              class="text-center font-size-16 pb-2"
              v-if="!isTicket && isDiscountApplied"
            >
              Discount[$]
            </th>
            <th class="text-center font-size-16 pb-2" v-if="false">Tax</th>
            <th class="text-center font-size-16 pb-2" v-if="!isTicket">
              Total
            </th>
            <th class="text-center font-size-16 pb-2"></th>
          </tr>
          <tr>
            <td
              :colspan="colspan"
              class="custom-border-bottom first-border"
            ></td>
          </tr>
        </thead>
        <tbody>
          <template v-if="lineItemLoading">
            <tr v-for="(df, ind) in 3" :key="ind" class="custom-border-bottom">
              <td class="custom-border-right pt-2">
                <v-skeleton-loader
                  class="custom-skeleton height-30px my-auto custom-skeleton-full-width"
                  width="100"
                  type="text"
                >
                </v-skeleton-loader>
                <v-skeleton-loader
                  class="custom-skeleton height-30px mr-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td width="200" class="custom-border-right">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td width="200" class="custom-border-right" v-if="!isTicket">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td
                width="200"
                class="custom-border-right"
                v-if="!isTicket && isDiscountApplied"
              >
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td
                width="200"
                class="custom-border-right"
                v-if="!isTicket && !levelOneHide && isTaxApplied"
              >
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td width="200" class="custom-border-right" v-if="!isTicket">
                <v-skeleton-loader
                  class="custom-skeleton height-30px mx-2 custom-skeleton-full-width"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
              <td>
                <v-skeleton-loader class="custom-skeleton mx-2" type="avatar">
                </v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="lodash.isEmpty(lineitems) === false">
              <template v-for="(item, index) in lineitems">
                <v-hover v-slot="lineItemHover" :key="index">
                  <tr class="custom-border-bottom">
                    <td
                      class="font-size-16 font-weight-500 custom-border-right"
                    >
                      <div class="detail-line-item py-4">
                        <v-chip
                          small
                          label
                          v-if="isQuotation && item.group_me"
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="teal darken-1"
                        >
                          {{ item.group_me }}
                        </v-chip>
                        <v-chip
                          small
                          label
                          v-if="canUpdate && getPermission('line-item:update')"
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="cyan darken-1"
                          v-on:click="updateProductDialog(item)"
                        >
                          Edit
                        </v-chip>
                        <v-chip
                          small
                          label
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="
                            item.product.product_type == 'goods'
                              ? 'cyan darken-1'
                              : 'orange darken-1'
                          "
                        >
                          {{ getProductType(item.product.product_type) }}
                        </v-chip>
                        <template v-if="lodash.isEmpty(item.visit) === false">
                          <v-chip
                            link
                            small
                            label
                            class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            :style="{
                              'background-color':
                                item.visit.status == 1 ? '#7db00e' : '#ef5733',
                            }"
                            v-on:click="pushToDetail"
                          >
                            {{ item.visit.barcode }}
                          </v-chip>
                        </template>
                        <v-chip
                          small
                          label
                          v-if="!item.product.show_name"
                          class="mr-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="teal darken-1"
                        >
                          Hide name in Document
                        </v-chip>
                        <v-hover v-slot="warrantyHover" open-delay="200">
                          <div class="d-inline-block">
                            <v-chip
                              link
                              v-if="item.warranty"
                              small
                              label
                              class="custom-status mr-4 font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="brown lighten-1"
                            >
                              {{ item.warranty_in_days }} Day(s)
                            </v-chip>
                            <LineItemWarrantyDetail
                              v-if="warrantyHover.hover"
                              :lineItem="item"
                            ></LineItemWarrantyDetail>
                          </div>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="200">
                          <div>
                            <LineItemProductDetail
                              v-if="hover"
                              :product="item.product"
                            ></LineItemProductDetail>
                            <p
                              v-on:click="updateProductDialog(item)"
                              class="m-0 pt-1 text-capitalize custom-dashed-border-bottom cursor-pointer custom-nowrap-ellipsis"
                            >
                              {{ item.product.name }}
                            </p>
                          </div>
                        </v-hover>
                        <read-more
                          class="custom-read-more text-capitalize font-weight-600 font-size-16 m-0 pt-1 text--secondary"
                          more-str="read more"
                          :text="item.description ? item.description : ''"
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </div>
                    </td>
                    <td
                      align="center"
                      width="200"
                      class="font-size-16 font-weight-500 custom-border-right"
                    >
                      {{ item.quantity }} {{ item.quantity_type }}
                    </td>
                    <td
                      v-if="!isTicket"
                      align="center"
                      width="200"
                      class="font-size-16 font-weight-500 custom-border-right"
                      :class="{ 'custom-top-border-white': !isGroup(item) }"
                    >
                      <template v-if="isGroup(item)">
                        {{ $accountingJS.formatMoney(item.rate) }}
                      </template>
                    </td>
                    <td
                      v-if="!isTicket && isDiscountApplied"
                      align="center"
                      width="200"
                      class="font-size-16 font-weight-500 custom-border-right"
                      :class="{ 'custom-top-border-white': !isGroup(item) }"
                    >
                      <template v-if="isGroup(item)">
                        {{ $accountingJS.formatMoney(item.discount) }}
                      </template>
                    </td>
                    <td
                      v-if="false"
                      align="center"
                      width="200"
                      class="font-size-16 font-weight-500 custom-border-right"
                    >
                      {{ $accountingJS.formatMoney(item.tax_amount) }}
                    </td>
                    <td
                      v-if="!isTicket"
                      align="center"
                      width="200"
                      class="font-size-16 font-weight-500"
                      :class="{ 'custom-top-border-white': !isGroup(item) }"
                    >
                      <template v-if="isGroup(item)">
                        {{ $accountingJS.formatMoney(item.total) }}
                      </template>
                    </td>
                    <td align="center" width="50">
                      <template
                        v-if="canUpdate && getPermission('line-item:delete')"
                      >
                        <div
                          v-if="lineItemHover.hover && lineitems.length > 1"
                          class="line-item-float-delete"
                        >
                          <v-btn
                            class="custom-bold-button white--text"
                            color="red lighten-1"
                            fab
                            small
                            v-on:click="deleteLineItem(index)"
                          >
                            <v-icon>mdi-delete-empty</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else-if="!lineItemLoading">
              <td :colspan="colspan" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no line item at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-if="canUpdate">
          <template v-if="lineItemLoading">
            <tr>
              <td :colspan="colspan">
                <v-skeleton-loader
                  class="custom-skeleton mx-2 my-2 height-30px custom-skeleton-full-width"
                  width="200"
                  type="text"
                >
                </v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-if="getPermission('line-item:create')">
              <td :colspan="colspan">
                <div>
                  <v-btn
                    class="mx-2 my-2 custom-bold-button white--text"
                    color="cyan"
                    v-on:click="lineItemDialog = true"
                  >
                    <v-icon small left>mdi-format-align-left</v-icon>
                    Add Line Item
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </tfoot>
      </table>
    </v-container>
    <v-row>
      <v-col cols="6" class="font-weight-500">
        <template v-if="lineItemLoading">
          <v-skeleton-loader
            class="custom-skeleton height-30px custom-skeleton-full-width"
            type="sentences"
          >
          </v-skeleton-loader>
        </template>
        <v-container v-else fluid>
          <read-more
            class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
            more-str="read more"
            :text="detail.additional_remarks ? detail.additional_remarks : ''"
            link="#"
            less-str="read less"
            :max-chars="200"
          >
          </read-more>
        </v-container>
      </v-col>
      <v-col cols="6" class="font-weight-500" v-if="!isTicket">
        <v-container fluid class="custom-border-left pt-0">
          <table class="width-100 vertical-align-middle">
            <template v-if="lineItemLoading">
              <tr
                v-for="(lt, id) in 2"
                :key="id"
                :class="{ 'custom-border-bottom': id < 4 }"
              >
                <td class="custom-border-right" colspan="2">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px custom-skeleton-full-width"
                    :class="{ 'mt-2': id > 0 }"
                    width="300"
                    type="text"
                  >
                  </v-skeleton-loader>
                </td>
                <td width="150">
                  <v-skeleton-loader
                    class="custom-skeleton mx-2 height-30px custom-skeleton-full-width"
                    :class="{ 'mt-2': id > 0 }"
                    type="text"
                  >
                  </v-skeleton-loader>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="custom-border-bottom">
                <td class="font-size-16 py-2 custom-border-right" colspan="2">
                  Sub Total
                </td>
                <td class="font-size-16 py-2 text-right" width="150">
                  {{ $accountingJS.formatMoney(detail.sub_total) }}
                </td>
              </tr>
              <tr v-if="isDiscountApplied" class="custom-border-bottom">
                <td class="font-size-16 py-2 custom-border-right" colspan="2">
                  Discount
                  <template v-if="discountType">[{{ discountType }}]</template>
                </td>
                <td class="font-size-16 py-2 text-right" width="150">
                  - {{ $accountingJS.formatMoney(detail.discount_amount) }}
                </td>
              </tr>
              <tr v-if="false" class="custom-border-bottom">
                <td class="font-size-16 py-2 custom-border-right" colspan="2">
                  Adjustment
                </td>
                <td class="font-size-16 py-2 text-right" width="150">
                  {{ $accountingJS.formatMoney(detail.adjustment) }}
                </td>
              </tr>
              <template v-if="!levelOneHide && isTaxApplied">
                <tr class="custom-border-bottom">
                  <td class="font-size-16 py-2 custom-border-right" colspan="2">
                    Taxable Amount
                  </td>
                  <td class="font-size-16 py-2 text-right" width="150">
                    {{
                      $accountingJS.formatMoney(
                        detail.sub_total - detail.discount_amount
                      )
                    }}
                  </td>
                </tr>
                <tr class="custom-border-bottom">
                  <td class="font-size-16 py-2 custom-border-right" colspan="2">
                    Total Tax [ {{ detail.tax_value }}% ]
                  </td>
                  <td class="font-size-16 py-2 text-right" width="150">
                    {{ $accountingJS.formatMoney(detail.tax_amount) }}
                  </td>
                </tr>
              </template>
              <tr>
                <td class="font-size-16 py-2 custom-border-right" colspan="2">
                  Grand Total
                </td>
                <td class="font-size-16 py-2 text-right" width="150">
                  {{ $accountingJS.formatMoney(detail.total) }}
                </td>
              </tr>
            </template>
          </table>
        </v-container>
      </v-col>
    </v-row>
    <template v-if="getPermission('line-item:create')">
      <LineItemSingle
        :isQuotation.sync="isQuotation"
        :isContract.sync="isContract"
        :isPurchaseOrder.sync="isPurchaseOrder"
        :isTicket.sync="isTicket"
        :lineItemDialog.sync="lineItemDialog"
        :targetUrl.sync="type"
        :parent.sync="detail.id"
        :visit.sync="visit"
        :isNewItem="true"
        :isVisitDetail.sync="isVisitDetail"
        v-on:success:line-item="successLineItem"
        v-on:close:line-item="lineItemDialog = false"
      ></LineItemSingle>
    </template>
    <template v-if="getPermission('line-item:update')">
      <LineItemUpdate
        :isQuotation.sync="isQuotation"
        :isContract.sync="isContract"
        :isPurchaseOrder.sync="isPurchaseOrder"
        :isTicket.sync="isTicket"
        :lineItemDialog.sync="lineItemUpdate"
        :targetUrl.sync="type"
        :parent.sync="detail.id"
        :visit.sync="visit"
        :updateData.sync="lineUpdateData"
        :isVisitDetail.sync="isVisitDetail"
        v-on:success:line-item="successLineItem"
        v-on:close:line-item="lineItemUpdate = false"
      ></LineItemUpdate>
    </template>
    <template v-if="getPermission('line-item:delete')">
      <LineItemDelete :deleteDialog="deleteLineItemDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.title }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.title }}</span> ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteLineItemConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteLineItemDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </LineItemDelete>
    </template>
  </div>
</template>

<script>
import LineItemDelete from "@/view/pages/partials/Delete.vue";
import LineItemSingle from "@/view/pages/partials/Line-Item-Single.vue";
import LineItemUpdate from "@/view/pages/partials/Line-Item-Single.vue";
import LineItemProductDetail from "@/view/pages/partials/Line-Item-Product-Detail.vue";
import LineItemWarrantyDetail from "@/view/pages/partials/Line-Item-Warranty-Detail.vue";
import { DELETE, QUERY } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      lineUpdateData: [],
      deleteLoading: false,
      deleteLineItemDialog: false,
      lineItemDialog: false,
      lineItemUpdate: false,
      deleteDetail: {},
      lineitems: [],
      parent: 0,
      timeout: null,
      lineItemLoading: true,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
      default: "job",
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    deleteLineItemDialog(param) {
      if (!param) {
        this.deleteDetail = new Object();
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        this.getLineItemList();
      },
    },
  },
  methods: {
    isGroup(item) {
      if (this.isQuotation && item.group_me) {
        if (item.group_primary) {
          return true;
        }
        return false;
      }
      return true;
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    updateProductDialog(item) {
      if (this.canUpdate && this.getPermission("line-item:update")) {
        this.lineUpdateData = new Array(item);
        this.lineItemUpdate = true;
      }
    },
    pushToDetail() {
      if (
        !this.isEngineerChannel() ||
        this.isVisitDetail ||
        this.lodash.isEmpty(this.visit)
      ) {
        return false;
      }
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: { id: this.visit.id },
        })
      );
    },
    getLineItems(parent) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (parent) {
            let formData = {};
            formData[_this.type] = parent;
            if (
              _this.isVisitDetail &&
              _this.lodash.isEmpty(_this.visit) === false
            ) {
              formData["visit"] = _this.lodash.toSafeInteger(_this.visit.id);
            }
            _this.$store
              .dispatch(QUERY, {
                url: "line-item",
                data: formData,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Parent is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    successLineItem() {
      this.getLineItemList();
      this.deleteLineItemDialog = false;
      this.lineItemDialog = false;
      this.lineItemUpdate = false;
      this.$emit("load:parent");
    },
    deleteLineItem(index) {
      this.deleteDetail = new Object();
      if (this.lodash.isEmpty(this.lineitems[index]) === false) {
        this.deleteLineItemDialog = true;
        let title = new Array();
        if (this.lodash.isEmpty(this.lineitems[index].product) === false) {
          title.push(
            this.lineitems[index].product.product_type == "goods"
              ? "Product"
              : "Service"
          );
          title.push(this.lineitems[index].product.name);
        }
        this.deleteDetail = {
          index: index,
          id: this.lineitems[index].id,
          title: title.join(" - "),
        };
      }
    },
    deleteLineItemConfirm() {
      const _this = this;
      const deletePromise = () => {
        return new Promise((resolve, reject) => {
          try {
            let lineItem = _this.lodash.toSafeInteger(_this.deleteDetail.id);
            if (lineItem > 0) {
              _this.$store
                .dispatch(DELETE, { url: "line-item/" + lineItem })
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject("invalid line item id");
            }
          } catch (error) {
            reject(error);
          }
        });
      };
      if (_this.lodash.isEmpty(_this.deleteDetail) === false) {
        _this.deleteLoading = true;
        deletePromise()
          .then(() => {
            _this.lineitems.splice(_this.deleteDetail.index, 1);
            _this.deleteLineItemDialog = false;
            _this.getLineItemList();
            _this.$emit("load:parent");
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.deleteLoading = false;
            _this.deleteDetail = new Object();
          });
      }
    },
    getLineItemList() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.lineItemLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getLineItems(_this.parent)
          .then((response) => {
            _this.lineitems = response.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.lineItemLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.getLineItemList();
  },
  components: {
    LineItemDelete,
    LineItemSingle,
    LineItemUpdate,
    LineItemProductDetail,
    LineItemWarrantyDetail,
  },
  computed: {
    levelOneHide() {
      return this.isQuotation || this.isContract;
    },
    isDiscountApplied() {
      return this.detail.discount_amount && this.detail.discount_amount > 0;
    },
    isTaxApplied() {
      return this.detail.tax_amount && this.detail.tax_amount > 0;
    },
    colspan() {
      let totalColumns = 7;
      if (this.isTicket) {
        return 3;
      }
      if (!this.isDiscountApplied) {
        --totalColumns;
      }
      if (this.levelOneHide || !this.isTaxApplied) {
        --totalColumns;
      }
      return totalColumns;
    },
    discountType() {
      if (this.detail.discount_value_type === 2) {
        return this.detail.discount_value + " %";
      }
      return null;
    },
  },
};
</script>
